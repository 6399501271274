import React, { createContext, useEffect, useState } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../services/auth.service';
import TeamService from '../../services/team.service';
import {
    getUserPreferences,
    selectCanFetchData,
    selectCurrentUser,
    selectTeamList,
    setTeamList,
} from '../../redux/slices/app/user.slice';
// import userService from '../../services/user.service';

export const UserContext = createContext({
    userPreferences: null,
    updateUserPreferences: () => {},
    refreshUserPreferences: () => {},
    currentUser: {},
    updatingData: false,
    updatedUserId: 0,
});

export const UserContextProvider = ({ children }) => {
    const dispatch = useDispatch();
    const canFetchData = useSelector(selectCanFetchData);
    const currentUser = useSelector(selectCurrentUser);
    const teamList = useSelector(selectTeamList);
    // using SSE for updating infos
    const [listeningBroadcastUser, setListeningBroadcastUser] = useState(false);
    // const [listeningBroadcastTeam, setListeningBroadcastTeam] = useState(false);
    const [userBroadcastMsg, setUserBroadcastMsg] = useState(false);
    const [updatingData, setUpdatingData] = useState(false);
    const [updatedUserId, setUpdatedUserId] = useState(0);

    const handleBroadcastMsg = async (event) => {
        // console.log('🚀 ~ handleBroadcastMsg ~ event USER:', event);
        try {
            setUpdatedUserId(0);
            const data = JSON.parse(event.data);
            const user = AuthService.getCurrentUser();
            // user updated
            if (data.type === 'User') {
                setUpdatingData(true);
                setUpdatedUserId(data.id);
                if (data.id === user.userId) {
                    console.log('should update');
                    await AuthService.getSession();
                    const updatedUser = AuthService.getCurrentUser();
                    dispatch(selectCurrentUser({ ...updatedUser, jobName: updatedUser?.job?.name }));
                }
                setUpdatingData(false);
            }
            if (data.type === 'Preference' && data.user !== user.userId && data.id === user.userId) {
                console.log('should update user preferences', data, currentUser.id);
                await dispatch(getUserPreferences());
            }
        } catch (error) {
            console.log('🚀 ~ handleBroadcastMsg ~ error:');
        }
    };

    const [updatedTeam, setUpdatedTeam] = useState(null);
    const handleBroadcastTeamsMsg = async (event) => {
        // console.log('🚀 ~ handleBroadcastTeamsMsg ~ event: TEAMS', event);
        try {
            const data = JSON.parse(event.data);
            if (data.type === 'Team') {
                // refresh list
                const teams = await TeamService.listTeams(false);
                const updatedTeamObject = teams.find((i) => i.id === +data.id);
                if (updatedTeamObject) {
                    setUpdatedTeam(updatedTeamObject);
                } else {
                    setUpdatedTeam({ id: data.id });
                }
                const indexTeam = teamList.findIndex((i) => i.id === +data.id);
                if (indexTeam !== -1 && updatedTeamObject) {
                    const cloneTeam = [...teamList];
                    cloneTeam[indexTeam] = updatedTeamObject;
                    dispatch(setTeamList(cloneTeam));
                } else {
                    dispatch(setTeamList(teams));
                }
            }
        } catch (error) {
            console.log('🚀 ~ handleBroadcastTeamsMsg ~ error:');
        }
    };
    // broadcast user
    useEffect(() => {
        if (userBroadcastMsg) {
            handleBroadcastMsg(userBroadcastMsg);
        }
    }, [userBroadcastMsg]);

    useEffect(() => {
        (async () => {
            if (!listeningBroadcastUser && window._env_.REACT_APP_USERS_API && canFetchData) {
                if (teamList.length === 0) {
                    const teams = await TeamService.listTeams(false);
                    dispatch(setTeamList(teams));
                }
                const accessToken = localStorage.getItem('accessToken');
                const headers = {};
                if (accessToken) {
                    headers.Authorization = `Bearer ${accessToken}`;
                    headers.Accept = 'text/event-stream';
                }
                await fetchEventSource(`${window._env_.REACT_APP_USERS_API}/broadcast`, {
                    openWhenHidden: true,
                    headers,
                    onopen: () => {
                        console.log('CONNECTED TO BROADCAST TEAM');
                    },
                    onmessage: (e) => {
                        setUserBroadcastMsg(e);
                        handleBroadcastTeamsMsg(e);
                    },
                    onerror: () => {},
                    onclose: () => {
                        console.log('BROADCAST USER TEAM USER CLOSED');
                        throw new Error('BROADCAST USER TEAM USER CLOSED');
                    },
                });
                setListeningBroadcastUser(true);
            }
        })();
    }, [listeningBroadcastUser, canFetchData]);
    

    return (
        <UserContext.Provider
            value={{
                updatedTeam,
                updatedUserId,
                updatingData,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
