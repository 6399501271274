import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const CalendarsTabContext = React.createContext();

export const CalendarsTabContextProvider = ({ children }) => {
    const [selectedCalendar, setSelectedCalendar] = useState(null);
    const [preserveSelectedCalendar, setPreserveSelectedCalendar] = useState(false);

    return (
        <CalendarsTabContext.Provider value={{ selectedCalendar, setSelectedCalendar, preserveSelectedCalendar, setPreserveSelectedCalendar }}>
            {children}
        </CalendarsTabContext.Provider>
    );
};
CalendarsTabContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
export default CalendarsTabContext;
