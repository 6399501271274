import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectPlanningCustomFields } from '../../redux/slices/app/planning.slice';

const CustomFieldsMenuContext = createContext({
    selectedCustomField: null,
    modalContent: null,
    setSelectedCustomField: () => {},
    setModalContent: () => {},
    shouldUpdatePlanning: false,
    setShouldUpdatePlanning: () => {}
});

const CustomFieldsMenuContextProvider = ({ children }) => {
    const planningCustomFields  = useSelector(selectPlanningCustomFields)
    const [selectedCustomField, setSelectedCustomField] = useState(null);
    const [shouldUpdatePlanning, setShouldUpdatePlanning] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    useEffect(() => {
        setSelectedCustomField(planningCustomFields.first());
    }, []);

    return (
        <CustomFieldsMenuContext.Provider
            value={{
                selectedCustomField,
                setSelectedCustomField,
                modalContent,
                setModalContent,
                shouldUpdatePlanning,
                setShouldUpdatePlanning
            }}
        >
            {children}
        </CustomFieldsMenuContext.Provider>
    );
};

CustomFieldsMenuContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { CustomFieldsMenuContext, CustomFieldsMenuContextProvider };
