import React, { createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useNeeds from '../../hooks/useNeeds';
import { selectPlanningSelected } from '../../redux/slices/app/planning.slice';

const PlanningNeedsContext = createContext(null);

const PlanningNeedsContextProvider = ({ children }) => {
    const { 
        selectedNeed, 
        setSelectedNeed, 
        needList, 
        loading, 
        error, 
        refreshNeedList, 
        fetchNeeds 
    } = useNeeds(true);

    const planningSelected = useSelector(selectPlanningSelected);


    useEffect(() => {
        if (planningSelected) {
            fetchNeeds(planningSelected.rootActivityId);
        }
    }, [planningSelected])

    return (
        <PlanningNeedsContext.Provider
            value={{
                selectedNeed,
                setSelectedNeed,
                NeedList: needList,
                refreshNeedList,
                loading,
                error,
                fetchNeeds,
            }}
        >
            {children}
        </PlanningNeedsContext.Provider>
    );
};

PlanningNeedsContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PlanningNeedsContext, PlanningNeedsContextProvider };
