import React, { createContext, useEffect, useState, useRef, useCallback } from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { detailedDiff } from 'deep-object-diff';
import { clone } from 'lodash';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import FolderService from '../../services/folder.service';
import CustomFieldService from '../../services/custom-field.service';
import ActivityService from '../../services/activity.service';
import { formatGanttTask } from '../../helpers/planning';
// import DefaultPlanningField from '../../constants/DefaultPlanningField';
// import { getFilterFieldIds } from '../../helpers/filters';
import userService from '../../services/user.service';
import taskStripes from '../../helpers/task-stripes';
import { getTextColor } from '../../helpers/text-color';
import calculateTaskDuration from '../../redux/utils/ActivitySliceUtils';
import { updateActivitiesDictionary } from '../../redux/thunks/activity-slice.thunk';
import { selectPlanningSelected, setTasksStripesStyles } from '../../redux/slices/app/planning.slice';
import { selectCanFetchData, selectCurrentUser } from '../../redux/slices/app/user.slice';
import {
    selectAllSubFolders,
    selectRootFolders,
    setRootFolders,
    setSubFoldersList,
} from '../../redux/slices/app/folder.slice';
import { refreshPlanningCustomFields, updatePlanning } from '../../redux/thunks/planning-slice.thunk';
/* eslint-disable-next-line */
import store from '../../redux/store';
import {
    removeActivity,
    selectActivityEntities,
    selectBaselineActivityEntities,
} from '../../redux/slices/app/activity.slice';
import { selectCalendarEntities, selectTimeUnitById } from '../../redux/slices/app/calendar.slice';
import { selectActiveTab, selectTabPreferences } from '../../redux/slices/app/tab.slice';
import { shouldUpdateSlices } from '../../redux/utils/ActivityCalendarSliceUtils';
import { TAB_TYPE } from '../../constants/Tabs';
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';
import i18n from '../../i18n';
import { updateActivityResourceVirtualTasks } from '../../redux/thunks/activity-calendar-slice.thunk';

const PlanningContext = createContext({});

// const REFRESH_ACTIVITIES_DICTIONARY = 5 * 60 * 1000;

const PlanningContextProvider = ({ children }) => {
    const dispatch = useDispatch();
    const planningSelected = useSelector(selectPlanningSelected);
    const tabPreferences = useSelector(selectTabPreferences);
    const baselineDictionary = useSelector(selectBaselineActivityEntities);
    const activitiesDictionary = useSelector(selectActivityEntities);
    const calendarsDictionary = useSelector(selectCalendarEntities);
    const rootFolders = useSelector(selectRootFolders);
    const subFoldersList = useSelector(selectAllSubFolders);
    const canFetchData = useSelector(selectCanFetchData);
    const currentUser = useSelector(selectCurrentUser);
    const activeTab = useSelector(selectActiveTab);
    const planningBroadcastController = useRef();
    // only for chart, we need to have a copy here because the chart context is only for chart components but we need selected items in printing
    const [listeningBroadcastFolder, setListeningBroadcastFolder] = useState(false);
    const [updatedFolderBroadcast, setUpdatedFolderBroadcast] = useState(null);
    // const [updatedFolderBroadcastData, setUpdatedFolderBroadcastData] = useState(null);
    let folderList = [];
    // broadcast
    const handleFolderBroadcastMsg = useCallback(
        async (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log('🚀 ~ file: PlanningContext.jsx:915 ~ handleFolderBroadcastMsg ~ data:', data);
                if (data.type === 'Folder') {
                    folderList = await FolderService.listFolders();
                    dispatch(setRootFolders(folderList));
                    let updatedFolder;
                    try {
                        updatedFolder = await FolderService.showFolder(data.id);
                    } catch (e) {
                        updatedFolder = null;
                    }
                    setUpdatedFolderBroadcast({ ...data, updatedData: updatedFolder });
                }
                if (data.type === 'Planning') {
                    let updatedPlanning;
                    try {
                        // console.log(
                        //     '🚀 ~ file: PlanningContext.jsx:931 ~ handleFolderBroadcastMsg ~ planningSelected:',
                        //     planningSelected
                        // );
                        console.log('send 1');
                        updatedPlanning = await FolderService.showPlanning(data.id);
                        const isFavorite = await userService.isFavoritePlanning(data.id);
                        updatedPlanning.isFavorite = isFavorite;
                        if (+planningSelected?.id === +data.id) {
                            // update planningSelected state
                            dispatch(updatePlanning(updatedPlanning));
                        }
                        setUpdatedFolderBroadcast({ ...data, updatedData: updatedPlanning });
                    } catch (e) {
                        updatedPlanning = null;
                        console.log('send2', e);
                        setUpdatedFolderBroadcast({ ...data, updatedData: updatedPlanning });
                    }
                }
            } catch (err) {
                console.log('🚀 ~ error FOLDER:');
            }
        },
        [planningSelected]
    );
    useEffect(() => {
        (async () => {
            if (!listeningBroadcastFolder && window._env_.REACT_APP_FOLDER_API && canFetchData) {
                const accessToken = localStorage.getItem('accessToken');
                const headers = {};
                if (accessToken) {
                    headers.Authorization = `Bearer ${accessToken}`;
                    headers.Accept = 'text/event-stream';
                }
                await fetchEventSource(`${window._env_.REACT_APP_FOLDER_API}/broadcast`, {
                    openWhenHidden: true,
                    headers,
                    onopen: () => {
                        console.log('CONNECTED TO BROADCAST FOLDER');
                    },
                    onmessage: (e) => {
                        handleFolderBroadcastMsg(e);
                    },
                    onerror: () => {
                        setListeningBroadcastFolder(false);
                    },
                    onclose: () => {
                        try {
                            throw new Error('BROADCAST FOLDER CLOSED');
                        } catch (error) {
                            console.log('🚀 ~ testSSEConnection ~ error:', error);
                        }
                    },
                });
                /// getting rootFolders
                if (rootFolders.length === 0) {
                    folderList = await FolderService.listFolders();
                    dispatch(setRootFolders(folderList));
                }

                setListeningBroadcastFolder(true);
            }
        })();
    }, [listeningBroadcastFolder, canFetchData]);

    // useEffect for updating subfolders
    useEffect(() => {
        if (!updatedFolderBroadcast || updatedFolderBroadcast.type !== 'Folder') return;

        const cloneSubfolders = new Map(subFoldersList);
        const updatedFolder = updatedFolderBroadcast.updatedData;

        if (updatedFolder && updatedFolder.parentId && cloneSubfolders.has(updatedFolder.parentId)) {
            const parentFolder = cloneSubfolders.get(updatedFolder.parentId);
            const existingFolderIndex = parentFolder.childFolders.findIndex((f) => f.id === updatedFolder.id);

            if (existingFolderIndex !== -1) {
                parentFolder.childFolders[existingFolderIndex] = updatedFolder;
            } else {
                parentFolder.childFolders.push(updatedFolder);
            }
        } else if (updatedFolder === null) {
            /* eslint-disable-next-line */
            for (const [, folder] of cloneSubfolders) {
                const childIndex = folder.childFolders?.findIndex((f) => f.id === +updatedFolderBroadcast.id);
                if (childIndex !== -1) {
                    folder.childFolders.splice(childIndex, 1);
                    break;
                }
            }
        }

        dispatch(setSubFoldersList(Object.fromEntries(cloneSubfolders)));
    }, [updatedFolderBroadcast, canFetchData]);
    // broadcast activity
    // const [listeningBroadcastActivity, setListeningBroadcastActivity] = useState(false);
    const [updatedDataActivityBroadcast, setUpdatedDataActivityBroadcast] = useState(null);
    const [updatedPlanningTreeBroadcast, setUpdatedPlanningTreeBroadcast] = useState(null);
    const [activityBroadcastData, setActivityBroadcastData] = useState(null);

    // let eventSourceActivity;
    // let eventSourceActivity;

    const handleActivityBroadcastMsg = useCallback(
        async (event) => {
            console.log('🚀 ~ event:', event);
            try {
                let data;
                if (event.data) {
                    // data = event.data.replace('"data":"', '"data":').replace('"colors":[]}"}', '"colors":[]}}')
                    data = JSON.parse(event.data);
                } else {
                    return;
                }
                // const currentUserInfo = authService.getCurrentUser();
                if (planningSelected && data.type === 'Activity' && window.ganttInstance) {
                    // should update
                    setTimeout(async () => {
                        const isTaskExist = window.ganttInstance.getTaskByServerId(+data.id) !== null;
                        console.log('🚀 ~ setTimeout ~ isTaskExist:', isTaskExist);
                        const isProjectTaskExist = window.ganttInstance.getTaskByServerId(data.rootId) !== null;
                        console.log('🚀 ~ setTimeout ~ isProjectTaskExist:', isProjectTaskExist);
                        if (isTaskExist || isProjectTaskExist) {
                            let updatedActivity;
                            try {
                                const planningColor = tabPreferences?.planning_color;
                                if (data.origin === 'FRONT') {
                                    if (planningColor) {
                                        updatedActivity = await ActivityService.showActivityWithColors(
                                            data.id,
                                            planningColor.id
                                        );
                                    } else {
                                        updatedActivity = await ActivityService.showActivity(data.id);
                                    }
                                } else {
                                    updatedActivity = data.data;
                                    // custom activity color management
                                    if (planningColor) {
                                        data.data.colors =
                                            data.data.unifiedfields.find((item) => item.id === planningColor.id)
                                                ?.colors || [];
                                    } else {
                                        data.data.colors = [];
                                    }
                                }
                                console.log('🚀 ~ setTimeout ~ data.data:', data.data);
                            } catch (e) {
                                updatedActivity = null;
                            }
                            let diffFields = [];
                            if (updatedActivity) {
                                // const affectedResources = [];
                                const oldActivityInfo = clone(activitiesDictionary[data.id]);
                                console.log(
                                    '🚀 ~ setTimeout ~ activitiesDictionary:',
                                    JSON.parse(JSON.stringify(activitiesDictionary))
                                );
                                // delete oldActivityInfo?.resourceNeeds;
                                delete oldActivityInfo?.taskDuration;
                                updatedActivity = clone({
                                    ...updatedActivity,
                                    baseLineStartDate: baselineDictionary[updatedActivity.identity]?.startDate || null,
                                    baseLineEndDate: baselineDictionary[updatedActivity.identity]?.endDate || null,
                                    baseLineProgress: baselineDictionary[updatedActivity.identity]?.avancement || 0,
                                    // affectedResources,
                                });

                                // diff old values accoording to new values
                                if (!oldActivityInfo) {
                                    diffFields = Object.keys(updatedActivity);
                                } else {
                                    const diff = detailedDiff(oldActivityInfo, updatedActivity);
                                    console.log('🚀 ~ file: PlanningContext.jsx:1027 ~ setTimeout ~ diff', diff);
                                    diffFields = [
                                        ...new Set([
                                            ...Object.keys(diff.added),
                                            ...Object.keys(diff.updated),
                                            ...Object.keys(diff.deleted),
                                        ]),
                                    ];
                                }
                                console.log(
                                    '🚀 ~ file: PlanningContext.jsx:1028 ~ setTimeout ~ diffFields',
                                    diffFields
                                );
                            } else {
                                const taskToDelete = window.ganttInstance.getTaskByServerId(+data.id);
                                if (taskToDelete) {
                                    window.ganttInstance.deleteTask(taskToDelete.id);
                                    dispatch(removeActivity(taskToDelete.serverId));
                                }
                            }
                            if (diffFields.length > 0) {
                                flushSync(() => {
                                    setUpdatedDataActivityBroadcast({
                                        id: +data.id,
                                        isTaskExist,
                                        context: data.context,
                                        isNewTask: activitiesDictionary[data.id] === undefined,
                                        updatedData: updatedActivity,
                                        diffFields,
                                    });
                                });
                            }
                        }
                    }, 1000);
                }
                // if (data.type === 'Assignment') {
                //     // eslint-disable-next-line

                // }
                if (data.type === 'Activity') {
                    setUpdatedPlanningTreeBroadcast({
                        ...data,
                    });
                }
                if (planningSelected && data.type === 'UnifiedField' && currentUser.userId !== data.user) {
                    let customField;
                    try {
                        customField = await CustomFieldService.getCustomFieldById(data.id);
                    } catch (error) {
                        customField = null;
                    }
                    if (customField) {
                        dispatch(refreshPlanningCustomFields('broadcast', customField));
                    } else {
                        dispatch(refreshPlanningCustomFields('delete', { id: Number(data.id) }));
                    }
                }
            } catch (error) {
                console.log('🚀 ~ error broadcast activity', error);
            }
        },
        [planningSelected, activitiesDictionary, baselineDictionary, tabPreferences]
    );

    useEffect(() => {
        if (activityBroadcastData) {
            handleActivityBroadcastMsg(activityBroadcastData);
        }
    }, [activityBroadcastData]);

    const connectToBroadcast = useCallback(async () => {
        console.log('🚀 ~ connectToBroadcast ~ listeningBroadcastActivity:', canFetchData, planningSelected);
        if (window._env_.REACT_APP_ACTIVITY_API && canFetchData && planningSelected) {
            console.log('BROADCAST', planningSelected);
            const accessToken = localStorage.getItem('accessToken');
            const headers = {};
            if (accessToken) {
                headers.Authorization = `Bearer ${accessToken}`;
                headers.Accept = 'text/event-stream';
            }
            planningBroadcastController.current = new AbortController();
            console.log('__________________CONNECTED TO NEW BROADCAST', planningSelected.name);
            try {
                await fetchEventSource(
                    `${window._env_.REACT_APP_ACTIVITY_API}/broadcast-pusher/${planningSelected.rootActivityId}`,
                    {
                        signal: planningBroadcastController.current.signal,
                        openWhenHidden: true,
                        headers,
                        onopen: () => {
                            console.log('__________________CONNECTED TO NEW BROADCAST', planningSelected.name);
                        },
                        onmessage: (e) => {
                            if (!window.disableBroadcastUpdate) {
                                flushSync(() => {
                                    setActivityBroadcastData(e);
                                });
                            }
                        },
                        onerror: () => {
                            try {
                                throw new Error();
                            } catch (error) {
                                console.log('🚀 ~ testSSEConnection ~ error:', error);
                            }
                        },
                        onclose: () => {
                            try {
                                throw new Error();
                            } catch (error) {
                                console.log('🚀 ~ testSSEConnection ~ error:', error);
                            }
                        },
                    }
                );
                // setListeningBroadcastActivity(true);
            } catch (error) {
                console.error('Error:', error);
                // throw error;
            }
        }
    }, [planningSelected]);

    useEffect(() => {
        const connecting = async () => {
            await connectToBroadcast();
        };
        connecting();
    }, [canFetchData, planningSelected]);

    // simulate broadcast
    const updateActivityByBroadcast = useCallback(
        (id) =>
            flushSync(() => {
                setActivityBroadcastData({
                    data: JSON.stringify({
                        context: 'ACTIVITY',
                        id,
                        rootId: planningSelected?.rootActivityId,
                        type: 'Activity',
                        origin: 'FRONT',
                    }),
                });
            }),
        [planningSelected]
    );

    const updateTaskStripes = () => {
        const stripedTasks = window.ganttInstance
            .getTaskByTime()
            .filter(taskStripes.hasStripes)
            .map(taskStripes.formatForState);
        dispatch(setTasksStripesStyles(stripedTasks));
    };

    const getTaskColor = (task, parameters) => {
        if (task.startDate === task.endDate) return parameters?.milestoneColor;
        return task.subActivitiesId.length > 0 ? parameters?.parentTaskBarColor : parameters?.simpleTaskBarColor;
    };

    useEffect(() => {
        if (!updatedDataActivityBroadcast || !planningSelected) return;

        const { updatedData, isTaskExist, isNewTask, id } = updatedDataActivityBroadcast;
        const isGroupement = tabPreferences?.filtered_group?.groupingType === 'custom_fields';

        const updateGanttTask = (task) => {
            const durationUnit = selectTimeUnitById(store.getState(), task.dayDefinitionId);
            const taskDuration = calculateTaskDuration(task, durationUnit);
            const taskColor = getTaskColor(task, tabPreferences.gantt_parameters);

            return formatGanttTask(task, {
                durationApi: taskDuration,
                roundedDuration: taskDuration ? Number(taskDuration).toFixed(1) : 0,
                realChildren: task.subActivitiesId,
                color: taskColor,
                textColor: getTextColor(taskColor),
                parentId: task.activityParentId,
            });
        };

        if (updatedData) {
            dispatch(updateActivitiesDictionary(updatedData.id, clone(updatedData)));

            if (isTaskExist && !isNewTask) {
                const oldData = window.ganttInstance.getTaskByServerId(id);
                if (isGroupement) {
                    updatedData.type = oldData.type;
                    updatedData.activityParentId = oldData.parent;
                } else {
                    updatedData.activityParentId = window.ganttInstance.getTaskByServerId(
                        updatedData.activityParentId
                    )?.id;
                }
                const task = updateGanttTask(updatedData);

                console.log('🚀 ~ useEffect ~ task:', task);
                window.ganttInstance.updateTaskByServerId(updatedData.id, task);
            } else if (!isTaskExist && !isGroupement) {
                const parentFakeId = window.ganttInstance.getTaskByServerId(updatedData.activityParentId)?.id;
                const task = updateGanttTask(updatedData);
                task.parent = parentFakeId;
                window.ganttInstance.addTask(task);
            }

            updateTaskStripes();
        } else if (isTaskExist) {
            window.ganttInstance.deleteTaskByServerId(id);
        }
        const showHistogram =
            activeTab?.tabType === TAB_TYPE.GANTT
                ? tabPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value
                : activeTab?.tabType === TAB_TYPE.RESOURCE;
        if (
            updatedDataActivityBroadcast &&
            !updatedDataActivityBroadcast.isNewTask &&
            shouldUpdateSlices(updatedDataActivityBroadcast.diffFields) &&
            showHistogram
        ) {
            dispatch(
                updateActivityResourceVirtualTasks({
                    taskId: updatedDataActivityBroadcast.id,
                    updatedData: updatedDataActivityBroadcast.updatedData,
                })
            );
        }
        updateActivityResourceVirtualTasks(updatedDataActivityBroadcast.id, updatedDataActivityBroadcast.updatedData);
    }, [updatedDataActivityBroadcast, planningSelected, tabPreferences]);

    return (
        <PlanningContext.Provider
            value={{
                updatedFolderBroadcast,
                setUpdatedFolderBroadcast,
                updatedDataActivityBroadcast,
                updateActivityByBroadcast,
                updatedPlanningTreeBroadcast,
                activitiesDictionary,
                planningSelected,
                calendarsDictionary,
            }}
        >
            {children}
        </PlanningContext.Provider>
    );
};
PlanningContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PlanningContext, PlanningContextProvider };
