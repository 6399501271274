import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PeriodsTabContext = createContext();

export const PeriodsTabContextProvider = ({ children }) => {
    const [selectedPeriod, setSelectedPeriod] = useState(null);

    return (
        <PeriodsTabContext.Provider value={{ selectedPeriod, setSelectedPeriod }}>
            {children}
        </PeriodsTabContext.Provider>
    );
};
PeriodsTabContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PeriodsTabContext;
