import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DaysTabContext = createContext();

export const DaysTabContextProvider = ({ children }) => {
    const [selectedDayDefinition, setSelectedDayDefinition] = useState(null);

    return (
        <DaysTabContext.Provider value={{ selectedDayDefinition, setSelectedDayDefinition }}>
            {children}
        </DaysTabContext.Provider>
    );
};
DaysTabContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DaysTabContext;
