import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { flushSync } from 'react-dom';
import WalletService from '../../services/wallet.service';
// import AuthService from '../../services/auth.service';
import { requestError } from '../../helpers/notification';
import userService from '../../services/user.service';
import { generateString } from '../../helpers/string-helper';
import { selectCurrentUser } from '../../redux/slices/app/user.slice';

const WalletContext = createContext({
    programList: [],
    isModalVisible: false,
    setSelectedProgram: () => {},
    setProgramList: () => {},
    refreshProgramList: () => {},
    setIsModalVisible: () => {},
});

const WalletContextProvider = ({ children }) => {
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    // table type to handle Recent, Favorite, Program
    const [tableType, setTableType] = useState('Program');
    const [programList, setProgramList] = useState([]);
    const currentUser = useSelector(selectCurrentUser)

    const { t } = useTranslation();
    const getAllProgramInfo = async (program) => 
        Promise.allSettled([WalletService.showLogo(program.id), userService
            .getProgramInfoByURL(program.url)]).then(([logoValue, infoValue]) => {
            const programListItem = {...program,key: generateString(20), logo: logoValue.value.logo, info: {...infoValue.value}}
            return programListItem
        })
    
    const getAllPrograms = async (type, user) => {
        setLoadingData(true);

        if (user.isPlAdmin) {
            WalletService.showPrograms()
                .then(async (data) => {
                    const listPromises = data.map((program) => getAllProgramInfo(program));
                    Promise.all(listPromises).then((programWithInfo) => {
                        // ajout des infos userService.getProgramInfoByURL(program.url)
                        setProgramList(programWithInfo);
                        setLoadingData(false);
                    });
                })
                .catch((error) => {
                    requestError(error, t('error_message.error_while_retrieving_program_data'));
                    setLoadingData(false);
                });
        } else {
            WalletService.showAuthorizedPrograms()
                .then(async (data) => {
                    const listPromises = data.map((program) => getAllProgramInfo(program));
                    Promise.all(listPromises).then((programWithInfo) => {
                        // ajout des infos userService.getProgramInfoByURL(program.url)
                        setProgramList(programWithInfo);
                        setLoadingData(false);
                    });
                })
                .catch((error) => {
                    requestError(error, t('error_message.error_while_retrieving_program_data'));
                    setLoadingData(false);
                });
        }
    };

    const refreshProgramList = (action, object) => {
        const oldPrograms = [];
        flushSync(() => {
            setProgramList((old) => {
                oldPrograms.push(...JSON.parse(JSON.stringify(old)));
                return old;
            });
        });
        switch (action) {
            case 'new': {
                oldPrograms.push(object);
                break;
            }
            case 'edit': {
                const indexTemplate = oldPrograms.findIndex((i) => i.id === object.id);
                if (indexTemplate > -1) {
                    oldPrograms[indexTemplate] = object;
                }
                break;
            }
            case 'delete': {
                const indexTemplate = oldPrograms.findIndex((i) => i.id === object.id);
                if (indexTemplate > -1) {
                    oldPrograms.splice(indexTemplate, 1);
                }
                break;
            }
            default:
        }
        setProgramList(() => oldPrograms);
    };

    useEffect(() => {
        const programInfo = JSON.parse(localStorage.getItem('prg'));
        if (!programInfo && currentUser?.isPlAdmin !== undefined) {
            getAllPrograms(tableType, currentUser);
        }
    }, [currentUser]);

    return (
        <WalletContext.Provider
            value={{
                programList,
                selectedProgram,
                setSelectedProgram,
                refreshProgramList,
                tableType,
                setTableType,
                loadingData,
                setLoadingData,
            }}
        >
            {children}
        </WalletContext.Provider>
    );
};

WalletContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { WalletContext, WalletContextProvider };
