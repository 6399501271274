import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CalendarService from '../../services/calendar.service';
import { requestError } from '../../helpers/notification';

export const CalendarMenuContext = createContext();


export const CalendarMenuContextProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const mainModalContents = {
        days: {
            key: 'days-tab',
            title: i18n.t('calendar_menu.change_calendar_days'),
        },
        periods: {
            key: 'periods-tab',
            title: i18n.t('calendar_menu.change_calendar_periods'),
        },
        calendars: {
            key: 'calendars-tab',
            title: i18n.t('calendar_menu.change_calendar_calendars'),
        },
    };
    const [modalContent, setModalContent] = useState(mainModalContents.days);
    const [activeTab, setActiveTab] = useState('days');
    const [periodsTab, setPeriodsTab] = useState('periods');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [timeSlots, setTimeSlots] = useState([]);
    const [dayDefinitions, setDayDefinitions] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [exclusions, setExclusions] = useState([]);
    const [calendars, setCalendars] = useState([]);

    const refreshTimeSlots = () =>
        new Promise((resolve, reject) => {
            CalendarService.listTimeSlots()
                .then((timeSlotsList) => {
                    // Trier les timeslots par ordre alphabétique
                    timeSlotsList.sort((t1,t2)=> t1.name.toLowerCase() > t2.name.toLowerCase());
                    setTimeSlots(timeSlotsList);
                    resolve({ timeSlots: timeSlotsList });
                    resolve();
                })
                .catch((error) => {
                    requestError(error, t('calendar_menu.time_slots_error_retrieving'));
                    reject();
                });
        });

    const refreshDayDefinitions = () =>
        new Promise((resolve, reject) => {
            CalendarService.listDayDefinitions()
                .then((dayDefinitionsList) => {
                    // Trier les definitions de jour par ordre alphabétique
                    dayDefinitionsList.sort((d1,d2)=> d1.name.toLowerCase() > d2.name.toLowerCase());
                    setDayDefinitions(dayDefinitionsList);
                    resolve({ dayDefinitions: dayDefinitionsList });
                })
                .catch((error) => {
                    requestError(error, t('calendar_menu.days_error_retrieving'));
                    reject();
                });
        });

    const refreshPeriods = () =>
        new Promise((resolve, reject) => {
            CalendarService.listPeriods()
                .then((periodsList) => {
                    // Trier les periodes par ordre alphabétique
                    periodsList.sort((p1,p2)=> p1.name.toLowerCase() > p2.name.toLowerCase());
                    setPeriods(periodsList);
                    resolve({ periods: periodsList });
                    resolve();
                })
                .catch((error) => {
                    requestError(error, t('calendar_menu.periods_error_retrieving'));
                    reject();
                });
        });

    const refreshCalendars = () =>
        new Promise((resolve, reject) => {
            CalendarService.listCalendars()
                .then((calendarsList) => {
                    // Trier les calendriers par ordre alphabétique
                    calendarsList.sort((c1,c2)=> c1.name.toLowerCase() > c2.name.toLowerCase());
                    setCalendars(calendarsList);
                    resolve({ calendars: calendarsList });
                    resolve();
                })
                .catch((error) => {
                    requestError(error, t('calendar_menu.calendars_error_retrieving'));
                    reject();
                });
        });

    const backToMainModal = (refreshMethod) => {
        if (refreshMethod && typeof refreshMethod === 'function') {
            refreshMethod().then(() => setModalContent(mainModalContents[activeTab]));
        } else {
            setModalContent(mainModalContents[activeTab]);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
        // reset context
        setModalContent(mainModalContents.days);
        setActiveTab('days');
        setPeriodsTab('periods');
    };

    useEffect(() => {
        if (activeTab === 'days' && modalContent.key !== 'days-tab') {
            setModalContent(mainModalContents.days);
        } else if (activeTab === 'periods' && modalContent.key !== 'periods-tab') {
            setModalContent(mainModalContents.periods);
        } else if (activeTab === 'calendars' && modalContent.key !== 'calendars-tab') {
            setModalContent(mainModalContents.calendars);
        }
    }, [activeTab]);

    return (
        <CalendarMenuContext.Provider
            value={{
                modalContent,
                setModalContent,
                backToMainModal,
                activeTab,
                setActiveTab,
                periodsTab,
                setPeriodsTab,
                isModalVisible,
                setIsModalVisible,
                showModal,
                onModalClose,
                timeSlots,
                setTimeSlots,
                dayDefinitions,
                setDayDefinitions,
                periods,
                setPeriods,
                exclusions,
                setExclusions,
                calendars,
                setCalendars,
                refreshTimeSlots,
                refreshDayDefinitions,
                refreshPeriods,
                refreshCalendars,
            }}
        >
            {children}
        </CalendarMenuContext.Provider>
    );
};

CalendarMenuContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CalendarMenuContext;
