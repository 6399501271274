import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
// import { zoomGanttToFit } from '../../helpers/zoom';

const PrintMenuContext = createContext({
    isModalVisible: false,
    modalContent: null,
    setIsModalVisible: () => {},
    setModalContent: () => {},
});

const PrintMenuContextProvider = ({ children }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
        // reset context
        setModalContent(null);
        // zoomGanttToFit();
    };

    return (
        <PrintMenuContext.Provider
            value={{
                isModalVisible,
                setIsModalVisible,
                modalContent,
                setModalContent,
                showModal,
                onModalClose,
            }}
        >
            {children}
        </PrintMenuContext.Provider>
    );
};

PrintMenuContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PrintMenuContext, PrintMenuContextProvider };
