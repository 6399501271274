import React, { createContext, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
/* eslint no-unused-vars: "off" */

// import resourceJSON from '../../mocks/resources/resources.json';
import ResourceService from '../../services/resource.service';
import CalendarService from '../../services/calendar.service';
import { UserContext } from '../app/UserContext';
import { requestError } from '../../helpers/notification';
import { findParentObject } from '../../helpers/tree-helper';

const ResourceContext = createContext({
    selectedResource: null,
    selectedSkill: null,
    resourceList: null,
    skillList: null,
    setSelectedResource: () => {},
    setSelectedSkill: () => {},
    setResourceList: () => {},
    setSkillList: () => {},
    refreshResourceList: () => {},
    refreshSkillList: () => {},
});

const ResourceContextProvider = ({ children }) => {
    const {t} = useTranslation('translation', {keyPrefix: 'resource_context'})
    const [selectedResource, setSelectedResource] = useState(null);

    const [selectedSkill, setSelectedSkill] = useState(null);
    const [resourceList, setResourceList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const { canFetchData } = useContext(UserContext);

    // resource clendar
    const [selectedResourceCalendar, setSelectedResourceCalendar] = useState(null);
    const [dayDefinitionList, setDayDefinitionList] = useState([]);
    const [modalContent, setModalContent] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const formatResources = (list, parent) =>
        list.map((el) => {
            const formattedValue = { ...el, key: el.id.toString(), parent, open: true };
            if (el.isLevel) {
                formattedValue.children = formatResources(el.children, el.id);
            }
            return formattedValue;
        });

    const getDayDefinitions = () =>
        new Promise((resolve) => {
            CalendarService.listDayDefinitions()
                .then((dayDefinitions) => {
                    // Trier les definitions de jour par ordre alphabétique
                    dayDefinitions.sort((d1, d2) => 
                        d1.name.toLowerCase() > d2.name.toLowerCase()
                    )
                    console.log("🚀 ~ file: ResourceContext.jsx ~ line 51 ~ .then ~ dayDefinitions", dayDefinitions)
                    setDayDefinitionList(dayDefinitions);
                    resolve({ dayDefinitions });
                })
                .catch((error) => {
                    requestError(error, t('error_get_day_definitions'));
                    resolve([]); // TODO change
                });
        });

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (window._env_.REACT_APP_ACTIVITY_API) {
    //             await getDayDefinitions();
    //             const list = await ResourceService.listResources(-1);
    //             setResourceList(formatResources([list], null));
    //             const skills = await ResourceService.listSkills();
    //             setSkillList(skills.map(i => ({...i, color: `#${Math.floor(Math.random()*16777215).toString(16)}`})));
    //         }
    //     };
    //     fetchData();
    // }, [canFetchData]);



    const refreshResourceList = (action, object) => {
        let oldResources = [];
        setResourceList((old) => {
            oldResources = old;
            return old;
        });
        // should always have parent object
        const parentObject = findParentObject(object.parent, oldResources);
        if (parentObject) {
            switch (action) {
                case 'new': {
                    parentObject.children.push(object);
                    break;
                }
                case 'edit': {
                    const oldParentObject = findParentObject(selectedResource.parent, oldResources);
                    if (oldParentObject.id !== parentObject.id) {
                        const oldChildIndex = oldParentObject.children.findIndex((i) => i.id === object.id);
                        oldParentObject.children.splice(oldChildIndex, 1);
                        parentObject.children.push(object);
                    } else {
                        const childIndex = parentObject.children.findIndex((i) => i.id === object.id);
                        parentObject.children[childIndex] = object;
                    }
                    break;
                }
                case 'delete': {
                    const childIndex = parentObject.children.findIndex((i) => i.id === object.id);
                    parentObject.children.splice(childIndex, 1);
                    break;
                }
                default:
            }
        }
        setResourceList(JSON.parse(JSON.stringify(oldResources)));
    };

    const refreshSkillList = (action, object) => {
        let oldSkills = [];
        setSkillList((old) => {
            oldSkills = old;
            return old;
        });
        switch (action) {
            case 'new': {
                oldSkills.push(object);
                break;
            }
            case 'edit': {
                const indexSkill = oldSkills.findIndex((i) => i.id === object.id);
                if (indexSkill > -1) {
                    oldSkills[indexSkill] = object;
                }
                break;
            }
            case 'delete': {
                const indexSkill = oldSkills.findIndex((i) => i.id === object.id);
                if (indexSkill > -1) {
                    oldSkills.splice(indexSkill, 1);
                }
                break;
            }
            default:
        }
        setSkillList(JSON.parse(JSON.stringify(oldSkills)));
    };

    return (
        <ResourceContext.Provider
            value={{
                selectedResource,
                setSelectedResource,
                resourceList,
                refreshResourceList,
                selectedSkill,
                setSelectedSkill,
                skillList,
                refreshSkillList,
                modalContent,
                setModalContent,
                isModalVisible,
                setIsModalVisible,
                selectedResourceCalendar,
                setSelectedResourceCalendar,
                dayDefinitionList,
            }}
        >
            {children}
        </ResourceContext.Provider>
    );
};

ResourceContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { ResourceContext, ResourceContextProvider };
